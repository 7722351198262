.ant-notification.ant-notification-bottomLeft {
  position: absolute;
}

.ant-notification-notice {
  background: rgba(255, 255, 255, 0.85);
}

.point-info-panel.disabled {
  color: #908e8e !important;
}

.request-location {
  padding: 2px 17px 3px;
  border: 1.4px solid #0fadec;
  border-radius: 7px;
  color: #0fadec;
  cursor: pointer;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  margin-bottom: 7px;
  margin-top: 10px;
}
.request-location:focus {
  outline: 0;
}