.aircraft-list-menu {
  background-color: #ffffff;
  border: 2px solid #4c9aff;
  height: 10px;
  width: 10px;
  padding: 10px;
  float: left;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  box-shadow: rgba(9, 30, 66, 0.08) 0 0 0 1px, rgba(9, 30, 66, 0.08) 0 2px 4px 1px;
  margin: 0;
  right: -16px;
  z-index: 9;
  top: 46vh;
  overflow: hidden;
}
.aircraft-list-menu:hover {
  border: 2px solid #4c9aff;
  transition: background-color 500ms linear;
  cursor: pointer;
  float: left;
  display: inline-block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  padding: 10px;
  animation: glow 0.6s linear infinite;
}
.aircraft-list-menu__slider {
  width: 100px;
  position: absolute;
  top: 2px;
  left: 0;
}
.aircraft-list-menu__open {
  left: 4px;
}
.aircraft-list-menu__close {
  left: -20px;
}
.aircraft-list-menu.show-menu .aircraft-list-menu__open {
  animation-name: flyOutLeft;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
}
.aircraft-list-menu.show-menu .aircraft-list-menu__close {
  animation-name: flyInRight;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
}
.aircraft-list-menu.hide-menu .aircraft-list-menu__open {
  animation-name: flyInLeft;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
}
.aircraft-list-menu.hide-menu .aircraft-list-menu__close {
  animation-name: flyOutRight;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
}

.material-icons {
  position: absolute;
  font-size: 17px;
}

@keyframes flyOutLeft {
  from {
    left: 4px;
  }
  to {
    left: -13px;
  }
}
@keyframes flyInRight {
  from {
    left: -25px;
  }
  to {
    left: 4px;
  }
}
@keyframes flyInLeft {
  from {
    left: 25px;
  }
  to {
    left: 4px;
  }
}
@keyframes flyOutRight {
  from {
    left: 4px;
  }
  to {
    left: 20px;
  }
}
@-webkit-keyframes glow {
  0% {
    box-shadow: 0 4px 10px rgba(76, 154, 255, 0.1), 0 0 0 0 rgba(76, 154, 255, 0.1), 0 0 0 5px rgba(76, 154, 255, 0.1), 0 0 0 10px rgba(76, 154, 255, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(76, 154, 255, 0.1), 0 0 0 5px rgba(76, 154, 255, 0.1), 0 0 0 10px rgba(76, 154, 255, 0.1), 0 0 0 20px rgba(76, 154, 255, 0);
  }
}
@keyframes glow {
  0% {
    box-shadow: 0 4px 10px rgba(76, 154, 255, 0.1), 0 0 0 0 rgba(76, 154, 255, 0.1), 0 0 0 5px rgba(76, 154, 255, 0.1), 0 0 0 10px rgba(76, 154, 255, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(76, 154, 255, 0.1), 0 0 0 5px rgba(76, 154, 255, 0.1), 0 0 0 10px rgba(76, 154, 255, 0.1), 0 0 0 20px rgba(76, 154, 255, 0);
  }
}