.rc-notification {
  left: auto !important;
  right: 75px;
}
.rc-notification .rc-notification-notice {
  padding: 0 !important;
  height: auto !important;
}
.rc-notification .notification-wrapper {
  background-color: #fff;
  padding-bottom: 10px;
  display: block;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
}
.rc-notification .notification-wrapper svg:last-child {
  display: none;
}
.rc-notification .rc-notification-notice {
  background-color: transparent;
}
.rc-notification .timestamp {
  font-size: 11px;
  letter-spacing: 0.2px;
  color: #888888;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
}
.rc-notification .tierName {
  padding-left: 30px;
}

.ant-collapse-content {
  color: #333 !important;
}

.ant-collapse-item {
  border: 0 !important;
}