.flight-report {
  background: #ffffff;
  height: 100%;
}
.flight-report .shadow {
  box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.12);
}
.flight-report .map {
  /* Override some original map styleings so we canlayoutproperly with flex */
}
.flight-report .map .map-wrapper__map {
  height: auto;
  position: relative !important;
}
.flight-report .top + .bottom {
  margin-top: 0;
  margin-bottom: 0;
}
.flight-report .bottom {
  height: 35%;
  transition: all;
  position: relative;
}
.flight-report .overlay-loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-self: center;
}
.flight-report .fill-parent {
  width: 100%;
  height: 100%;
}
.flight-report .fill-parent.hidden {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.flight-report .align-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}