.download-modal .ant-modal-header {
  border: 0;
}
.download-modal .ant-modal-footer {
  border: 0;
}
.download-modal__title-container {
  display: flex;
  flex-direction: column;
}
.download-modal__title-title {
  margin-bottom: 0.25rem;
}
.download-modal__title-subtitle {
  font-size: 0.8rem;
  font-weight: normal;
}
.download-modal__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.download-modal__option {
  display: flex;
  flex-direction: column;
}
.download-modal__icon-container, .download-modal__icon-container--disabled {
  width: 6rem;
  height: 6rem;
  padding: 0.25rem;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #3684fa;
}
.download-modal__icon-container--disabled {
  color: #b7b7b7;
}
.download-modal__icon {
  height: 2rem;
  width: 2rem;
}
.download-modal__checkbox {
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}
.download-modal__checkbox-label, .download-modal__checkbox-label--disabled {
  cursor: pointer;
}
.download-modal__checkbox-label--disabled {
  color: #b7b7b7;
}
.download-modal__option-description {
  font-size: 0.8rem;
}