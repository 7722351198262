.tick {
  content: " ";
  background-size: 10px 8px;
  background-repeat: no-repeat;
  background-position: 3px 4px;
  position: absolute;
  right: 16px;
  top: 8px;
  z-index: 2;
  color: #3b98fb;
  border: solid rgba(0, 0, 0, 0.8);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
  height: 14px;
  opacity: 1;
}