.event-timeline {
  overflow-y: auto;
  margin-left: 5%;
}
.event-timeline .event-timeline-row {
  display: flex;
  padding-bottom: 20px;
}
.event-timeline .event-timeline-row:last-child {
  padding-bottom: 0px;
}
.event-timeline .vertical-line-start {
  background: linear-gradient(#bfbfbf, #bfbfbf) no-repeat left 12px top 10px/1px 100%;
}
.event-timeline .vertical-line-finish {
  background: linear-gradient(#bfbfbf, #bfbfbf) no-repeat left 12px top 0px/1px 10px;
}
.event-timeline .event-timeline-row:not(:last-child):not(:first-child) {
  background: linear-gradient(#bfbfbf, #bfbfbf) no-repeat 12px/1px 100%;
}
.event-timeline .icon {
  height: 25px;
}
.event-timeline .text-container {
  display: "flex";
  flex-direction: column;
  padding-left: 10px;
}
.event-timeline .name {
  color: #000000a6;
  font-size: 15px;
}
.event-timeline .time {
  color: #8c8c8c;
  font-size: 13px;
}