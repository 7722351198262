.event-header {
  display: flex;
  margin-bottom: 30px;
}

.event-title {
  font-size: 20px;
  font-weight: 600;
  color: #262626;
  word-break: break-word;
}

.event-go-back-icon {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}
.event-go-back-icon:hover {
  cursor: pointer;
}

.event-departed-time {
  font-size: 13px;
  color: grey;
}