#mapContainer:-webkit-full-screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-wrapper {
  float: left;
  overflow: hidden;
  position: absolute !important;
}

.map-wrapper__map {
  height: 100vh;
  width: 100%;
  position: initial !important;
  overflow: hidden;
}

.info-window-label {
  font-weight: bold;
}

.gm-style-iw {
  text-align: center;
  position: absolute;
}

.gm-style-iw + button {
  display: none !important;
}

.gm-style button.gm-ui-hover-effect {
  top: 0px !important;
  right: 0px !important;
}

.gm-style button.gm-ui-hover-effect > img {
  width: 20px !important;
  height: 20px !important;
}

.gm-style .gm-style-iw {
  font-weight: 300;
  font-size: 13px;
  overflow: hidden;
}