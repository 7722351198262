.ant-popover .weather-view-radio {
  width: 208px;
}
.ant-popover .weather-view-radio span.ant-radio.ant-radio-checked:before {
  content: "";
  background-size: 10px 8px;
  background-repeat: no-repeat;
  background-position: 3px 4px;
  position: absolute;
  z-index: 2;
  color: #3b98fb;
  border: solid #171010;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 7px;
  height: 15px;
  left: 6px;
  top: 2px;
  opacity: 1;
}
.ant-popover .weather-view-radio label.ant-radio-wrapper:hover {
  background-color: #f8f8f8;
}
.ant-popover .weather-view-radio .ant-radio-checked .ant-radio-inner {
  border-color: transparent;
}
.ant-popover .weather-view-radio .ant-radio-inner:after {
  background-color: transparent;
}
.ant-popover .weather-view-radio .ant-radio-inner {
  border-color: transparent;
}
.ant-popover .weather-view-radio .ant-radio-wrapper:hover .ant-radio .ant-radio-inner {
  border-color: transparent;
}
.ant-popover .weather-view-radio label.ant-radio-wrapper {
  width: 100%;
  min-width: 200px;
  padding: 10px 15px;
}
.ant-popover .weather-view-radio span.ant-radio {
  float: right;
  position: absolute;
  right: 20px;
  top: 12px;
}
.ant-popover .weather-view-radio .clear-wrapper {
  width: 100%;
  min-width: 208px;
  padding: 10px 15px 3px;
  float: left;
}
.ant-popover .weather-view-radio .clear-wrapper .clear-weather {
  float: right;
  color: #4872DE;
  font-weight: bold;
  background-color: #f8f8f8;
  padding: 2px 7px;
  border-radius: 4px;
}
.ant-popover .weather-view-radio .clear-wrapper .clear-weather:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
.ant-popover span, .ant-popover label {
  outline: none;
}
.ant-popover .ant-popover-inner-content {
  padding: 0;
}
.ant-popover label.ant-radio-wrapper:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-popover label.ant-radio-wrapper:last-child {
  border-radius: 0 0 4px 4px;
}

.ant-radio-inner {
  background-color: transparent;
}