@-webkit-keyframes loadingOne {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadingOne {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loadingTwo {
  0% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@keyframes loadingTwo {
  0% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
.loading.active {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
}

.loading-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50px, -50px);
}

.loader div.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader .loader-one,
.loader .loader-two,
.loader .loader-three {
  background: black;
  height: 5px;
  width: 30px;
  margin: 5px;
  -webkit-animation: loadingOne 1s 0s infinite ease-in-out;
  animation: loadingOne 1s 0s infinite ease-in-out;
}

.loader .loader-two {
  -webkit-animation: loadingTwo 0.5s 0s infinite ease-in-out alternate;
  animation: loadingTwo 0.5s 0s infinite ease-in-out alternate;
}

.loader .loader-three {
  animation: loadingOne 1s 0s infinite ease-in-out reverse;
}